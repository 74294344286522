<template>
  <modal
    name="modal-execucao-manual"
    scrollable
    width="95%"
    height="auto"
    :clickToClose="false"
    @closed="aoFecharModalExecucaoManual"
  >
    <Toast position="top-right" />
    <ModalPreviewCampanhaTable
      :tableHeaders="tableHeaders"
      :tableContent="tableContent"
      v-if="tableContent"
    />
    <div style="padding: 5px 20px !important; height: auto">
      <div class="modal-preview-cabecalho m-3 d-flex align-items-center">
        <div
          class="back-arrow-squared mr-3"
          @click="$emit('fecharModalExecucaoManual')"
        >
          <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
        </div>
        <div class="flex-grow-1 text-center" v-if="campanha">
          {{ campanha.modelo_campanha_id }} - {{ campanha.mdc_nome }}
        </div>
      </div>
      <hr style="margin: 2px -20px 18px -20px" />
      <div class="d-flex align-items-center justify-content-end mb-2">
        <div class="d-flex align-items-center">
          <transition name="fade">
            <div
              class="total-de-mensagens-container"
              v-if="campanhaExecutada && totalDeMensagens !== null"
            >
              <span>Total de mensagens da campanha:</span>
              <strong>{{ totalDeMensagens }}</strong>
            </div></transition
          >
          <transition name="fade-slower">
            <div
              class="btn botao-filtro-ver-preview mr-5 text-uppercase"
              @click="solicitarPrevia()"
              v-if="btnMostrarModalVisible || isBankAPI"
              ref="btnExecutarPreviewModalExecucaoManuaCompleto"
            >
              Solicitar Prévia Completa
            </div></transition
          >
          <button
            class="btn botao-executar-campanha text-uppercase mr-3"
            variant="primary"
            @click="toggleBotaoParaLancar()"
            :class="btnExecutarCampanha ? 'active' : ''"
            :disabled="btnExecutarCampanhaDisabled"
            v-if="previewJaFoiExecutado"
          >
            {{ btnExecutarCampanha ? "confirmar" : "Executar Campanha" }}
          </button>
          <div
            class="btn botao-filtro-preview text-uppercase mr-3"
            variant="primary"
            @click="solicitarPreviaApenasCount()"
            ref="btnExecutarPreviewModalExecucaoManual"
            v-if="!isBankAPI"
          >
            Solicitar Prévia
          </div>
          <button
            class="btn botao-filtro-add text-uppercase"
            @click="adicionarNovoFiltro()"
          >
            + Adicionar novo filtro
          </button>
        </div>
      </div>
      <div
        class="d-flex align-center justify-content-center"
        v-if="listaDeFiltros && camposPossiveisDoFiltro && opreadoresFiltro"
      >
        <NestedDraggable
          v-if="campanha && listaDeFiltros"
          :group="0"
          :camposPossiveisDoFiltro="camposPossiveisDoFiltro"
          :opreadoresFiltro="opreadoresFiltro"
          :listaDeFiltros="listaDeFiltros"
          :isDraggable="true"
          :sistemaLicenciadaId="sistema_licenciada_id"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import NestedDraggable from "../cadastro-componentes/NestedDraggable.vue";
import ModalPreviewCampanhaTable from "./ModalPreviewCampanhaTable.vue";
import API_LINKS from "../../api.links";
import ApiService from "@/core/services/api.service";
import Toast from "primevue/toast";
import * as Sentry from "@sentry/vue";
import Utils from "@/core/utils/Utils";

export default {
  components: { NestedDraggable, ModalPreviewCampanhaTable, Toast },
  props: ["campanha"],
  data() {
    return {
      executouPreviewCompleto: false,
      current_uid_preview_count: null,
      current_uid_preview: null,
      listaDeFiltros: null,
      modeloFiltrosObrigatorios: null,
      modelo_id: null,
      sistema_licenciada_id: null,
      opreadoresFiltro: null,
      camposPossiveisDoFiltro: null,
      campanhaExecutada: false,
      modelo_campanha_id: null,
      totalDeMensagens: null,
      tableHeaders: null,
      tableContent: null,
      btnMostrarModalVisible: false,
      btnExecutarCampanha: false,
      btnExecutarCampanhaDisabled: false,
      previewJaFoiExecutado: false,
      liscenced_databases: [],
      liscenced_options: []
    };
  },
  computed: {
    isBankAPI() {
      const bank = this.liscenced_databases.find((banco) => {
        return banco.sistema_licenciada_id == this.sistema_licenciada_id;
      });
      if (bank) {
        return ["API_E", "API_G"].includes(bank.banco_id);
      }
      return false;
    }
  },
  mounted() {
    this.obterOpcoesLicenciada();
  },
  methods: {
    async obterOpcoesLicenciada() {
      const response = await ApiService.get(
        API_LINKS.sistemaLicenciada,
        "",
        false
      );
      this.liscenced_options = response.data.sistemas.map((v) => {
        return {
          value: v.sistema_licenciada_id,
          text: v.sli_titulo
        };
      });
      this.liscenced_databases = response.data.sistemas.map((v) => {
        return {
          ...v
        };
      });
      return response;
    },
    aoFecharModalExecucaoManual() {
      this.previewJaFoiExecutado = false;
      this.executouPreviewCompleto = false;
      this.current_uid_preview = null;
      this.current_uid_preview_count = null;
      this.tableHeaders = null;
      this.tableContent = null;
      this.btnMostrarModalVisible = false;
    },
    adicionarNovoFiltro() {
      this.listaDeFiltros = Utils.addNovoFiltro(
        JSON.parse(JSON.stringify(this.listaDeFiltros)),
        this.modeloFiltrosObrigatorios
      );
      this.listaDeFiltros.forEach((v, i) => {
        if (!(Array.isArray(v.listaDeFiltros) && v.listaDeFiltros.length)) {
          if (!v.mfi_e_ou) v.mfi_e_ou = "AND";
        }
        delete this.listaDeFiltros[this.listaDeFiltros.length - 1].mfi_e_ou;
      });
    },
    mostrarModalPreviewTable() {
      this.$modal.show("modal-preview-table");
    },
    mostrarExecucaoManual() {
      this.$modal.show("modal-confirmacao-execucao-manual");
    },
    toggleBotaoParaLancar() {
      if (this.btnExecutarCampanha) {
        this.executarCampanha();
        this.btnExecutarCampanhaDisabled = true;
      }
      this.btnExecutarCampanha = true;
    },
    fecharExecucaoManual() {
      this.$modal.hide("modal-confirmacao-execucao-manual");
    },
    fecharConfirmacaoExecucaoManual() {
      this.$modal.hide("modal-confirmacao-execucao-manual");
    },
    async solicitarPreviaApenasCount() {
      try {
        this.campanhaExecutada = true;
        this.totalDeMensagens = null;
        this.tableHeaders = null;
        this.tableContent = null;
        this.btnMostrarModalVisible = false;

        if (this.current_uid_preview_count) {
          await this.buscarDadosPreviaApenasCount(
            this.current_uid_preview_count
          );
          return;
        }
        const res = await ApiService.post(API_LINKS.campanhaPreviewPost, {
          apenas_count: true,
          campanha: {
            sistema_licenciada_id: this.campanha.sistema_licenciada_id,
            modelo_id: this.campanha.modelo_id,
            mdc_variaveis_filtro_nativo:
              this.campanha.mdc_variaveis_filtro_nativo,
            filtros: this.listaDeFiltros
          }
        });

        if (res.data.requisicao.sucesso) {
          this.current_uid_preview_count = res.data.requisicao.uid;
          await this.buscarDadosPreviaApenasCount(
            this.current_uid_preview_count
          );
        } else if (res.data.requisicao.error) {
          this.exibirToast("error", "Erro", res.data.requisicao.error, 7000);
        } else {
          this.exibirToast(
            "error",
            "Erro",
            "Ocorreu um erro na solicitação.",
            7000
          );
        }
      } catch (error) {
        console.error("Erro ao solicitar prévia apenas count:", error);
        this.exibirToast(
          "error",
          "Erro inesperado",
          "Ocorreu um erro ao processar a solicitação.",
          5000
        );
      }
    },

    async buscarDadosPreviaApenasCount(uid) {
      try {
        const res2 = await ApiService.get(
          `${API_LINKS.campanhaPreviewDados}/${this.sistema_licenciada_id}/${uid}`
        );

        if (!res2.data.requisicao.sucesso) {
          this.exibirToast(
            "error",
            "Erro",
            "Falha ao obter dados da prévia.",
            5000
          );
          return;
        }

        const requisicao = res2.data.requisicao.requisicao || {};

        if (requisicao.resultado?.code === "EREQUEST") {
          this.exibirToast(
            "error",
            "Erro",
            JSON.stringify(requisicao.resultado),
            7000
          );
          this.current_uid_preview_count = null;
          return;
        }

        if (requisicao.status === "PENDENTE") {
          this.definirBotaoTexto("Tentar Novamente");
          this.exibirToast(
            "warn",
            "Resultado Pendente",
            "O contador da prévia ainda está sendo processado. Tente novamente.",
            7000
          );
          return;
        }

        this.processarDadosPreviaApenasCount(requisicao.resultado);
      } catch (error) {
        console.error("Erro ao buscar dados da prévia apenas count:", error);
        this.exibirToast(
          "error",
          "Erro inesperado",
          "Não foi possível buscar os dados da prévia.",
          5000
        );
      }
    },

    processarDadosPreviaApenasCount(resultado) {
      this.totalDeMensagens =
        Array.isArray(resultado) &&
        resultado.length === 1 &&
        resultado[0].hasOwnProperty("count_qtd")
          ? resultado[0].count_qtd
          : Array.isArray(resultado)
          ? resultado.length
          : 0;

      const previewIsCompleta =
        Array.isArray(resultado) &&
        !(resultado.length === 1 && resultado[0].hasOwnProperty("count_qtd"));

      if (this.totalDeMensagens > 0) {
        this.tableHeaders = Object.keys(resultado[0]);
        this.tableContent = resultado;
        this.btnMostrarModalVisible = true;
        this.previewJaFoiExecutado = true;
        this.definirBotaoTexto("Solicitar Prévia");
        this.executouPreviewCompleto = previewIsCompleta;
      } else {
        this.exibirToast(
          "warn",
          "Resultado Vazio",
          "A prévia completa não retornou nenhum resultado.",
          7000
        );
        this.tableHeaders = null;
        this.tableContent = null;
      }

      this.current_uid_preview_count = null;
    },

    exibirToast(severity, summary, detail, life = 5000) {
      this.$toast.add({ severity, summary, detail, life });
    },

    async solicitarPrevia() {
      try {
        if (this.executouPreviewCompleto) {
          this.mostrarModalPreviewTable();
          return;
        }

        this.campanhaExecutada = true;
        this.tableHeaders = null;
        this.tableContent = null;

        if (this.current_uid_preview) {
          await this.buscarDadosPrevia(this.current_uid_preview);
          return;
        }

        const res = await ApiService.post(API_LINKS.campanhaPreviewPost, {
          campanha: {
            sistema_licenciada_id: this.campanha.sistema_licenciada_id,
            modelo_id: this.campanha.modelo_id,
            mdc_variaveis_filtro_nativo:
              this.campanha.mdc_variaveis_filtro_nativo,
            filtros: this.listaDeFiltros
          }
        });

        if (res.data.requisicao.sucesso) {
          this.current_uid_preview = res.data.requisicao.uid;
          await this.buscarDadosPrevia(this.current_uid_preview);
        } else {
          this.exibirToast(
            "error",
            "Erro",
            "Ocorreu um erro na solicitação.",
            7000
          );
        }
      } catch (error) {
        const errorId = Sentry.captureException(error);
        this.exibirToast(
          "error",
          "Erro inesperado",
          "Ocorreu um erro ao processar a solicitação. Código do erro: " +
            errorId,
          5000
        );
      }
    },

    async buscarDadosPrevia(uid) {
      try {
        const res2 = await ApiService.get(
          `${API_LINKS.campanhaPreviewDados}/${this.sistema_licenciada_id}/${uid}`
        );

        if (!res2.data.requisicao.sucesso) {
          this.exibirToast(
            "error",
            "Erro",
            "Falha ao obter dados da prévia.",
            5000
          );
          return;
        }

        const requisicao = res2.data.requisicao.requisicao || {};

        if (requisicao.resultado?.code === "EREQUEST") {
          this.exibirToast(
            "error",
            "Erro",
            JSON.stringify(requisicao.resultado),
            7000
          );
          this.current_uid_preview = null;
          return;
        }

        if (requisicao.status === "PENDENTE") {
          this.definirBotaoTexto("Tentar Novamente");
          this.exibirToast(
            "warn",
            "Resultado Pendente",
            "O resultado da prévia ainda está sendo processado. Tente novamente.",
            7000
          );
          return;
        }
        this.processarDadosPrevia(requisicao.resultado);
      } catch (error) {
        const errorId = Sentry.captureException(error);
        this.exibirToast(
          "error",
          "Erro inesperado",
          "Não foi possível buscar os dados da prévia. Código do erro: " +
            errorId,
          5000
        );
      }
    },

    processarDadosPrevia(resultado) {
      this.totalDeMensagens = Array.isArray(resultado)
        ? resultado.length
        : null;

      if (this.totalDeMensagens > 0) {
        this.tableHeaders = Object.keys(resultado[0]);
        this.tableContent = resultado;
        this.definirBotaoTexto("Solicitar Prévia");

        setTimeout(() => {
          this.mostrarModalPreviewTable();
          this.executouPreviewCompleto = true;
          this.previewJaFoiExecutado = true;
        }, 500);
        this.current_uid_preview = null;
      } else if (!Array.isArray(resultado)) {
        if (typeof resultado === "object") {
          this.exibirToast(
            "error",
            "Resultado inconsistente",
            JSON.stringify(resultado),
            7000
          );
        } else {
          this.exibirToast("error", "Resultado inconsistente", resultado, 7000);
        }
        this.tableHeaders = null;
        this.tableContent = null;
        this.current_uid_preview = null;
      } else {
        this.exibirToast(
          "warn",
          "Resultado Vazio",
          "A prévia completa não retornou nenhum resultado.",
          7000
        );
        this.tableHeaders = null;
        this.tableContent = null;
        this.current_uid_preview = null;
      }
    },

    definirBotaoTexto(texto) {
      if (this.$refs.btnExecutarPreviewModalCadastroCampanha) {
        this.$refs.btnExecutarPreviewModalCadastroCampanha.innerText = texto;
      }
    },

    executarCampanha() {
      this.campanhaExecutada = true;
      this.totalDeMensagens = null;
      this.tableHeaders = null;
      this.tableContent = null;
      this.btnMostrarModalVisible = false;

      ApiService.post(API_LINKS.disparoManual + "/" + this.modelo_campanha_id, {
        filtros: this.listaDeFiltros
      })
        .then((res) => {
          this.fecharConfirmacaoExecucaoManual();
          setTimeout(() => {
            this.btnExecutarCampanhaDisabled = false;
            this.btnExecutarCampanha = false;
          }, 5000);

          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "A campanha foi disparada com sucesso.",
            life: 7000
          });
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao disparar campanha manualmente.",
            life: 7000
          });
        });
    }
  },

  watch: {
    campanha: {
      handler(val) {
        if (val) {
          if (val.modelo_campanha_id) {
            this.modelo_campanha_id = val.modelo_campanha_id;
            ApiService.get(API_LINKS.campanha + "/" + val.modelo_campanha_id)
              .then((res) => {
                this.listaDeFiltros = res.data.campanha.FILTROS;
                this.modeloFiltrosObrigatorios =
                  res.data.campanha.filtros_obrigatorios;
                this.modelo_id = res.data.campanha.modelo_id;
                this.sistema_licenciada_id =
                  res.data.campanha.sistema_licenciada_id;
              })
              .then(() => {
                ApiService.get(
                  API_LINKS.campanha + "/filtro/" + this.modelo_id
                ).then((res2) => {
                  this.camposPossiveisDoFiltro = res2.data.filtros.map(
                    (filtro) => {
                      return {
                        fil_campo: filtro.fil_campo,
                        fil_titulo: filtro.fil_titulo,
                        filtro_id: filtro.filtro_id,
                        ope_titulo: filtro.ope_titulo,
                        fil_tipo_dado: filtro.fil_tipo_dado,
                        fil_tipo: filtro.fil_tipo,
                        fil_mascara: filtro.fil_mascara
                      };
                    }
                  );
                });
              });

            ApiService.get(API_LINKS.campanha + "/operador").then((res) => {
              this.opreadoresFiltro = res.data.operadores.map((v) => {
                return {
                  ope_titulo: v.ope_titulo,
                  ope_sinal: v.ope_sinal,
                  operador_id: v.operador_id
                };
              });
            });
          }
        } else {
          this.campanhaExecutada = false;
          this.totalDeMensagens = null;
          this.btnMostrarModalVisible = false;
          this.tableHeaders = false;
          this.tableContent = false;
          this.btnExecutarCampanha = false;
          this.btnExecutarCampanhaDisabled = false;
        }
      },
      immediate: true,
      deep: true
    },
    listaDeFiltros: {
      handler(val, oldVal) {
        this.previewJaFoiExecutado = false;
        this.campanhaExecutada = false;
        if (
          this.$refs.btnExecutarPreviewModalExecucaoManual ||
          this.$refs.btnExecutarPreviewModalExecucaoManuaCompleto
        ) {
          this.current_uid_preview = null;
          if (this.$refs.btnExecutarPreviewModalExecucaoManual) {
            this.$refs.btnExecutarPreviewModalExecucaoManual.innerText =
              "Solicitar Prévia";
          }
          this.tableHeaders = null;
          this.tableContent = null;
          this.btnMostrarModalVisible = false;
          this.executouPreviewCompleto = false;
        }

        function recursiveRemoveLastMfiEOu(lista) {
          if (lista[lista.length - 1].listaDeFiltros.length === 0) {
            delete lista[lista.length - 1].mfi_e_ou;
            return;
          }
          recursiveRemoveLastMfiEOu(lista[lista.length - 1].listaDeFiltros);
        }
        function removeNull(lista) {
          const listaInitialLength = lista.length;
          for (let i = 0; i < listaInitialLength; i++) {
            if (lista[i] === null) {
              const removedElement = lista.splice(i, 1);
            }
          }
        }

        function removeLastMfiEOu(lista) {
          // Último elemento de hierarquia 0
          // lista[lista.length - 1]

          // Último elemento de hierarquia 1
          // lista[lista.length - 1].listaDeFiltros[
          //         lista[lista.length - 1].listaDeFiltros.length - 1
          //       ]

          // Último elemento de hierarquia 2
          // lista[lista.length - 1].listaDeFiltros[
          //   lista[lista.length - 1].listaDeFiltros.length - 1
          // ].listaDeFiltros[
          //   lista[lista.length - 1].listaDeFiltros[
          //     lista[lista.length - 1].listaDeFiltros.length - 1
          //   ].listaDeFiltros.length - 1
          // ]

          // Verifica se a lista tem algum elemento
          if (lista.length > 0) {
            // Verifica se a lista do último elemento da lista de hierarquia 0 tem algum elemento
            if (lista[lista.length - 1].listaDeFiltros.length > 0) {
              // Verifica se o último elemento da lsita de hierarquia 1 tem algum elemento
              if (
                lista[lista.length - 1].listaDeFiltros[
                  lista[lista.length - 1].listaDeFiltros.length - 1
                ].listaDeFiltros.length > 0
              ) {
                // console.log(
                //   "última lista de hierarquia 2",
                //   lista[lista.length - 1].listaDeFiltros[
                //     lista[lista.length - 1].listaDeFiltros.length - 1
                //   ].listaDeFiltros[
                //     lista[lista.length - 1].listaDeFiltros[
                //       lista[lista.length - 1].listaDeFiltros.length - 1
                //     ].listaDeFiltros.length - 1
                //   ]
                // );
                const listThree =
                  lista[lista.length - 1].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros.length - 1
                  ].listaDeFiltros;
                if (listThree[listThree.length - 1].listaDeFiltros.length > 0) {
                  delete listThree[listThree.length - 1].listaDeFiltros[
                    listThree[listThree.length - 1].listaDeFiltros.length - 1
                  ].mfi_e_ou;
                } else {
                  delete lista[lista.length - 1].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros.length - 1
                  ].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros[
                      lista[lista.length - 1].listaDeFiltros.length - 1
                    ].listaDeFiltros.length - 1
                  ].mfi_e_ou;
                }
              } else {
                // console.log(
                //   "última lista de hierarquia 1",
                //   lista[lista.length - 1].listaDeFiltros[
                //     lista[lista.length - 1].listaDeFiltros.length - 1
                //   ]
                // );
                delete lista[lista.length - 1].listaDeFiltros[
                  lista[lista.length - 1].listaDeFiltros.length - 1
                ].mfi_e_ou;
              }
            } else {
              delete lista[lista.length - 1].mfi_e_ou;
              // console.log(
              //   "última lista de hierarquia 0",
              //   lista[lista.length - 1]
              // );
            }
          }
        }
        // Adicionar o mfi_e_ou em todos os elementos e eliminar o último
        function recursiveAddMfiToAllFilters(lista) {
          if (lista.length === 0) {
            return;
          }
          for (let i = 0; i < lista.length; i++) {
            if (lista[i].listaDeFiltros.length > 0) {
              recursiveAddMfiToAllFilters(lista[i].listaDeFiltros);
            } else if (!lista[i].hasOwnProperty("mfi_e_ou")) {
              lista[i].mfi_e_ou = "AND";
            }
          }
        }
        function addMfiToAllFilters(lista) {
          if (lista.length > 0) {
            lista.forEach((filtro0) => {
              if (
                !filtro0.hasOwnProperty("mfi_e_ou") &&
                filtro0.listaDeFiltros.length === 0
              ) {
                filtro0.mfi_e_ou = "AND";
              }
              if (filtro0.listaDeFiltros.length > 0) {
                filtro0.listaDeFiltros.forEach((filtro1) => {
                  if (
                    !filtro1.hasOwnProperty("mfi_e_ou") &&
                    filtro1.listaDeFiltros.length === 0
                  ) {
                    filtro1.mfi_e_ou = "AND";
                  }
                  if (filtro1.listaDeFiltros.length > 0) {
                    filtro1.listaDeFiltros.forEach((filtro2) => {
                      if (
                        !filtro2.hasOwnProperty("mfi_e_ou") &&
                        filtro2.listaDeFiltros.length === 0
                      ) {
                        filtro2.mfi_e_ou = "AND";
                      }
                      if (filtro2.listaDeFiltros.length > 0) {
                        filtro2.listaDeFiltros.forEach((filtro3) => {
                          if (!filtro3.hasOwnProperty("mfi_e_ou")) {
                            filtro3.mfi_e_ou = "AND";
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        }

        function changeHierarchyOfLastElement(lista, initialHierarchy = 0) {
          if (lista[lista.length - 1].listaDeFiltros.length !== 0) {
            changeHierarchyOfLastElement(
              lista[lista.length - 1].listaDeFiltros,
              lista[lista.length - 1].hierarquia
            );
          }
          lista[lista.length - 1].hierarquia = initialHierarchy;
        }
        if (this.listaDeFiltros) {
          addMfiToAllFilters(val);
          // recursiveAddMfiToAllFilters(val);
          removeLastMfiEOu(val);
          // changeHierarchyOfLastElement(val);
          // recursiveRemoveLastMfiEOu(val);
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.total-de-mensagens-container {
  margin-right: 30px;
  font-size: 18px;
}

.total-de-mensagens-container span {
  margin-right: 8px;
}

.botao-filtro-preview {
  border: 1px solid #ffa800;
  color: #ffa800;
  border-radius: 5px;
  z-index: 10;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.botao-executar-campanha {
  border: 1px solid #f32424;
  color: #f32424;
  border-radius: 5px;
  z-index: 10;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

.botao-executar-campanha.active {
  border: 1px solid #525e75;
  color: #525e75;
}

.botao-filtro-ver-preview {
  border: 1px solid #001d6e;
  color: #001d6e;
  border-radius: 5px;
  z-index: 10;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-executar-campanha {
  transition: 0s all;
  text-transform: uppercase;
  font-weight: 600;
  /* width: 200px; */
  padding: 12px 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #007bff;
  /* background: #ffa800; */
  line-height: 1;
  color: #fff;
}

.botao-filtro-add {
  border: 1px solid #1bc5bd;
  color: #1bc5bd;
  padding: 2px 6px;
  border-radius: 5px;
  z-index: 10;
  padding: 4px 12px;
  height: 38px;
}

.botao-voltar {
  color: #808080;
  transition: all 0.1s linear;
  cursor: pointer;
}

.bota-voltar:hover {
  transform: scale(1.1);
}

.modal-preview-cabecalho {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 1.3rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-slower-enter-active,
.fade-slower-leave-active {
  transition: opacity 2s;
}

.fade-slower-enter,
.fade-slower-leave-to {
  opacity: 0;
}

.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
